:root {
    --color-light-theme:hsl(216, 63%, 50%);
    --color-accent-primary:hsla(216, 63%, 50%, 0.267);
}

.globalNavs {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 80px;
}

.globalNavs>.globalNavsItem {
    padding: 5px 10px;
    margin: 0;
    border-radius: 5px;
    font-size: 0.7rem;
    font-weight: 600;
    color: var(--color-light-theme);
    background: var(--color-accent-primary);
    text-decoration: none;
    transition: all ease-in-out .2s;
    text-wrap: nowrap;
}

.globalNavsItem:not(:first-child){
    margin-left: 3px;
}

.globalNavs>.globalNavsItem:hover{
    font-size: 0.9rem;
}


.globalNavs{
    overflow-x: auto;
    scroll-behavior: smooth;
}

.globalNavs::-webkit-scrollbar {
    display: none;
}

@media (max-width:768px) {
    .wrapper {
        display: none;
    }
}

.wrapper {
    position: relative;
}

.left,
.right {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.left:hover,
.right:hover {
    background: aliceblue;
}

.left {
    left: -10px;
}

.right {
    right: -30px;
}