.animate::before {
    animation: turn 1s infinite linear;
}

@keyframes turn{
    0% {
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}