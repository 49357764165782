.box {
    position: relative;
}

.img {
    border-radius: 50%;
    margin: 5px;
    object-fit: cover;
    /* object-position: center; */
    transition: all ease-in-out .3s;
}

.imgUpd {
    border-radius: 50%;
    margin: 5px;
    object-fit: cover;
    /* object-position: center; */
    transition: all ease-in-out .3s;
}

.box:hover .imgUpd {
    filter: blur(1px);
}

.tools {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    position: absolute;
    bottom: 0;
    transition: all .3s ease-in-out;
    left: 50%;
    transform: translateX(-50%);
}
.box:hover .tools{
    opacity: 1;
}

.objectPositionCenter {
    object-position: center;
}

.objectPositionTop {
    object-position: top;
}

.objectPositionBottom {
    object-position: bottom;
}