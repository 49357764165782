.image {
    object-fit: cover;
    border: 1px solid #4b6aa7;
    padding: 2px;
}

.objectPositionCenter {
    object-position: center;
}

.objectPositionTop {
    object-position: top;
}

.objectPositionBottom {
    object-position: bottom;
}