.change_item_indicator {
    width: 50px;
    height: 100%;
    position: relative;
    text-align: center;
}
.indicator{
    position: absolute;
    left: 50%;
    border-left: 1px dashed #0075FF;
    top: 20px;
    bottom: 0;
}