.gradientBg {
    background: #365d78;
    background: radial-gradient(circle, #365d78 0, #dfe9eb 0, #e1e7eb 15%, #eaf2f0 36%, #f0efe8 54%, #eeebe5 74%, #e7ebee 100%);
}

.hex {
    position: relative;
    width: 150px;
    height: 180px;
    background-color: #9d9d9d1d;
    margin: 10px;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    transform: rotate(90deg);
    cursor: pointer;
    text-decoration: none;
    /* border-radius: 50px;  */
  }
  
  .content {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
  }

  .hex::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(5, 5, 5, 0.051);
    border-radius: 50%;
    transform: scale(0);
    opacity: 0;

    transition: opacity 0.6s, transform 0.6s;
  }
  
  .hex:hover::after {
    transform: scale(4);
    opacity: 1;
    transition: opacity 0.6s, transform 0.6s;
  }